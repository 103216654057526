const coursesModel = {
  sectionTitle:"Extra Courses",
  courses: [
    "“Scrum Developer Certified”, by Agile Academy.",
    "“Design Sprint Lab”, by Agile Academy.",
    "“The React Bootcamp“, by Scrimba",
    "“iOS Development Bootcamp” by WAPP Developers.",
    "“Técnicas de Ingeniería de Software aplicadas a la Programación” by SW Factory JCenter.",
    "“SQL Fundamentals” by SoloLearn.",
    "“Java Fundamentals” by SoloLearn.",
    "“Swift Fundamentals” by SoloLearn.",
    "“JavaScript Fundamentals” by SoloLearn.",
    "“Goethe-Zertifikat A1: Start Deutsch 1”, by Goethe-Institut.",
  ]
}

export default coursesModel