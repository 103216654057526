const headerModel = {
  resumeTitle: {
    name: "Ricardo Sarabia",
    description: "Software Developer"
  },
  about: "Self learner. Tech fan. Kotlin Lover. Deeply engaged with native mobile development. Occasionally making some web stuff.",
  contact: {
    mail:"ric.sarabia@icloud.com",
    phone:"55 3168 4570"
  }
}

export default headerModel


