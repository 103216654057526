import React from 'react'
import './../../App.css'

function School(props){
  return <div className='ListCard School'>
    <p>
      {props.schoolData.name}: {props.schoolData.career}
    </p>
    <p>
      {props.schoolData.period}, {props.schoolData.location}
    </p>
  </div>
}

export default School