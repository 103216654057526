import heaerModel from './header/HeaderModel'
import React from 'react';
import Name from './header/Name';
import About from './header/About';
import Contact from './header/Contact';
import headerModel from './header/HeaderModel';

const Header = () => 
  <header>
    <Name nameData={heaerModel.resumeTitle} />
    <Contact contactData={headerModel.contact} />
    <About aboutData={headerModel.about}/>
  </header>

export default Header;