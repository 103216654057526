const skillsModel = {
  sectionTitle:"Relevant Skills",
  skillSets: [
    {
      title:"Android",
      skills:[
        "Kotlin", 
        "Java",
        "XML",
        "Gradle",
        "Flavors",
        "Retrofit, Gson",
        "Push Notifications",
        "Google Maps",
        "Architecture Components",
        "MVP",
        "MVVM",
        "Realm",
        "Room",
        "SQLite"
      ]
    },
    {
      title:"iOS",
      skills:[
        "Swift",
        "UIKit",
        "Storyboards",
        "Auto Layout",
        "SwiftUI",
        "MapKit",
        "CoreData",
        "Push Notifications",
        "Cocoa Pods",
        "Alamofire",
        "Realm"
      ]   
    },
    {
      title:"Flutter",
      skills:[
        "Dart",
        "Stateless Widgets",
        "Stateful Widgets",
        "Material Components Widgets",
        "Cupertino Widgets",
        "Provider",
        "Scoped Model",
        "Bloc",
        "SQLite"
      ]
    },
    {
      title:"React",
      skills:[
        "JSX",
        "JavaScript ES6",
        "Class Components",
        "Functional Components",
        "Props",
        "React Context",
        "Redux",
        "Hooks",
        "React Router"
      ]
    },
    {
      title:"General",
      skills:[
        "Git",
        "REST API calls",
        "SQL",
        "NoSQL",
        "Serverless architecture",
        "Firebase",
        "Google Cloud Platform",
        "Unit Testing",
        "Architecture patterns (MVVM, MVP, MVC)",
        "SOLID principles",
        "Scrum"
      ]
    },
    {
      title:"IDEs and Tools",
      skills:[
        "Android Studio",
        "VSCode",
        "Xcode",
        "WebStorm",
        "SourceTree",
        "Postman",
        "Jira",
        "Trello"
      ]
    },
    {
      title:"Slightly experienced on:",
      skills:[
        "Svelte",
        "Node.js",
        "Npm",
        "Express.js",
        "TypeScript"
      ]
    }
  ]
}
export default skillsModel