import React from 'react';
import './../../App.css'

 const SkillSet = (props) => <div className="ListCard">
  <h3>{props.title}</h3>
  <ul>
    {props.skills.map(skill =>
      <li>{skill}</li>
    )}
  </ul>
</div>

export default SkillSet