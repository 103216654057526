const educationModel = {
  title: "Education",
  schools: [
    {
      name:"Instituto Politécnico Nacional, (UPIICSA)",
      career:"Computer Science", 
      period:"2007 - 2011",
      location:"México City"
    },
    {
      name:"Instituto Politécnico Nacional, (CET Walter Cross Buchanan)",
      career:"Baccalaureate / Computer Networks",
      period:"2005 - 2007",
      location:"México City"
    }
  ]
}

export default educationModel