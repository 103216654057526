import React from 'react'
import experienceModel from './experience/ExperienceModel'
import Job from "./experience/Job"

const Experience = ()=>(
    <>
      <h2>{experienceModel.sectionTitle}</h2>
      { experienceModel.jobs.map(j => <Job jobData={j}/>) }
    </>
)


export default Experience