import React from 'react'
import './../../App.css'

const Job = (props)=>{
    return <div className='ListCard'>
        <h3>{props.jobData.company}: {props.jobData.role}</h3>
        <p>{props.jobData.period}, {props.jobData.location}</p>
        <p>{props.jobData.description}</p>
    </div>
}

export default Job