import React from 'react'
import coursesModel from './courses/CoursesModel'
import '../App.css'

function Courses(){
  return <>
    <h2> {coursesModel.sectionTitle} </h2>
    <ul className="ListCard">
      { coursesModel.courses.map(course => <li>{course}</li>) }
    </ul>
  </>
}

export default Courses