const experienceModel = {
  sectionTitle: "Experience",
  jobs: [
    {
      company: "Onikom",
      role: "Mobile Developer",
      period: "2017 - NOW",
      location: "Cuauhtémoc, Mexico City",
      description: "Developing mobile applications on iOS and Android platforms for clients/brands like: ADO, Nadro, CFE, AutoTul, Corona X Parley, T-Fal, among others."
    },
    {
      company: "TICsMX",
      role: "Developer & Systems Integrator",
      period: "2015 - 2017",
      location: "Iztacalco, Mexico City",
      description: "Developing tech solutions covering communication, access control, and data processing for clients like: UNAM, American School Foundation, Imperquimia, STyFE, CDMX Government, among others."
    },
    {
      company: "Gobierno del Estado de México",
      role: "Data Analyst",
      period: "2013 - 2015",
      location: "Nezahualcóyotl, Mexico State",
      description: "Analysing and processing high volume data bases, generating reports, automating bureaucratic processes and some tech support activities."
    },
    {
      company: "Towa",
      role: "​Developer",
      period: "2012",
      location: "Coyoacán, México City",
      description: "Developing changes and new features for batch processes and backend on IBM mainframe platform at BBVA bank."
    }
  ]
}
export default experienceModel