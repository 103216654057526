import React from 'react'
import educationModel from './education/EducationModel'
import School from './education/School'

function Education(){
  return <>
    <h2>{educationModel.title}</h2>
    <div className="HorizontalContainer Education">
      {educationModel.schools.map(s => <School schoolData={s} />)}
    </div>
  </>
}

export default Education