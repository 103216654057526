import React from 'react'
import SkillSet from './skills/SkillSet'
import skillsModel from './skills/SkillsModel'

const Skills = () => 
<>
  <h2>{skillsModel.sectionTitle}</h2>
  <div className="HorizontalContainer">
    {skillsModel.skillSets.map(skillSet => 
      <SkillSet 
        title={skillSet.title} 
        skills={skillSet.skills} 
      />
    )}
  </div>
</>

export default Skills