import React from 'react';
import './App.css';
import Header from './app/Header';
import Skills from './app/Skills';
import Works from './app/Works';
import Experience from "./app/Experience"
import Education from './app/Education';
import Courses from './app/Courses';
import Footer from './app/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Skills />
      <Experience />
      <Education />
      <Courses />
      <Footer />
    </div>
  );
}

export default App;
