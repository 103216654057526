import React from 'react';
import logo from './logo.svg';

const Name = (props) => 
  <div className="App-header MainText">
   <img src={logo} className="App-logo" alt="logo" />
    <div>{props.nameData.name}</div>
   <div className="App-link">{props.nameData.description}</div>
  </div>

export default Name;